<template>
  <v-row justify="center" class="mb-1">
    <v-col
      class="px-3"
       :class="{
          'card-programa': $vuetify.breakpoint.mdAndUp,
          'card-programa-sm': $vuetify.breakpoint.smOnly,
          'card-programa-xs': $vuetify.breakpoint.xsOnly,
        }"
    >
      <v-card :elevation="1" class="pb-0 rounded-sm fill-height" :loading="true">
        <template #progress>
          <v-progress-linear
            :value="InfoSemana.evi ? InfoSemana.evi : 0 "
            :color="progressColor"
          />
          <div class="d-flex justify-space-between aling-items-center pb-2">
            <span class="blue-grey--text px-4 caption">Efectividad: <strong class="font-weight-bold">{{InfoSemana.evi ? InfoSemana.evi : '0' }}%</strong></span>
            <span class="primary--text px-4 caption font-weight-bold">Total Visitas: {{totalVisita}}</span>
          </div>
        </template>
        <v-card-title class="pb-2 pt-1 text-h5">
          <span class="primary--text font-weight-bold text-capitalize">
            {{ dia.fecha | diaSemana }}
            {{ Object.keys(dia).length > 0 ? momento(dia.fecha).format("DD") : '' }}
          </span>
          <v-spacer />
          <v-chip
            label
            small
            :color="statusDia(InfoSemana)"
            dark
            class="px-1 font-weight-medium label-visitas"
          >
            {{ Object.keys(dia).length > 0 && dia.datos_dia ? `${InfoSemana.cv ? InfoSemana.cv : '0' }/${InfoSemana.cp}` : '0/0' }}
            <span class="yellow--text text--lighten-2">{{ InfoSemana.cfp ? '-'+InfoSemana.cfp : ''}}</span>
          </v-chip>
        </v-card-title>
        <v-card-text class="py-1 px-3 d-flex flex-wrap justify-space-between">
          <div>
            <span class="blue-grey--text caption">
              <v-icon
                color="icono"
                size="16"
                class="mt-n1"
              >mdi-clock-outline</v-icon>
              <template v-if="InfoSemana.hora_inicio && InfoSemana.hora_inicio !== ''">
                {{ dia.fecha + ' ' + InfoSemana.hora_inicio | hora }}
              </template>
              <template v-else>
                00:00
              </template>
            </span>
            <span class="blue-grey--text mx-1 caption">
              <v-icon
                color="red"
                size="16"
                class="mt-n1"
              >mdi-clock-outline</v-icon>
              <template v-if="InfoSemana.hora_fin && InfoSemana.hora_fin !== ''">
              {{ dia.fecha + ' ' + InfoSemana.hora_fin | hora }}
              </template>
              <template v-else>
                00:00
              </template>
            </span>
            <span class="primary--text caption font-weight-bold">
              <v-icon
                color="primary"
                size="16"
                class="mt-n1"
              >mdi-alarm-check</v-icon>
              <template v-if="InfoSemana.dtv !== null">
                {{ InfoSemana.dtv }}
              </template>
              <template v-else>
                00:00:00
              </template>
            </span>
          </div>
          <v-badge
            :color="dia.comentario !== null ? 'icono' : 'transparent'"
            dot
            overlap
            :bordered="dia.comentario !== null"
          >
            <v-btn
              icon
              x-small
              color="primary"
              @click="sendCommet"
            >
              <v-icon >mdi-comment-text-outline</v-icon>
            </v-btn>
          </v-badge>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import moment from 'moment'
import {totalClientesNoPlanificado} from '@/util/helpers'
import es from 'moment/locale/es'
moment.locale('es', es)

const totalDuracionVisita = (item) => {
  if (item.clientes.length > 0) {
    const duration = item.clientes.reduce((acc, cli) => {
      if (cli?.visita?.hora_inicio && cli?.visita?.hora_fin) {
        const inicio = moment(`${cli.visita.fecha} ${cli.visita.hora_inicio}`);
        const fin = moment(`${cli.visita.fecha} ${cli.visita.hora_fin}`);
        return acc + fin.diff(inicio)
      }
      return acc
    }, 0)
    const hours = Math.floor(moment.duration(duration).asHours()).toString()

    return `${ hours.length < 2 ? hours.padStart(2, '0') : hours }:${ moment.utc(duration).format("mm:ss") }`
  }
  return null

}

export default {
  name: 'HeaderPrograma',
  props:{
    semana: {
      type: Array,
      default: () => ([]),
    },
    dia: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    colorsProgress: ['blue', 'purple', 'orange', 'pink lighten-1', 'indigo', 'teal'],
  }),
  methods:{
    momento: moment,
    statusDia() {
      if(Object.keys(this.dia).length > 0) {
        let now = moment().format("YYYY-MM-DD");
        if(moment(this.dia.fecha).isSameOrAfter(now)) {
          return this.dia.datos_dia && this.dia.datos_dia.cv > 0
            ? 'icono'
            : 'blue-grey lighten-1'

        }
        else if(moment(this.dia.fecha).isBefore(now)) {
           return this.dia.datos_dia && this.dia.datos_dia.cv > 0
            ? 'icono'
            : 'red'
        }
      }
    },
    sendCommet(){
      this.$emit('comment', this.dia)
    }

    // statusDia(dia) {
    //   if(this.semana.length > 0) {
    //     let now = moment().format("YYYY-MM-DD");
    //     if(moment(dia.fecha).isSameOrAfter(now)) {
    //       return dia.cv > 0
    //         ? 'icono'
    //         : 'blue-grey lighten-1';

    //     }
    //     else if(moment(dia.fecha).isBefore(now)) {
    //        return dia.cv > 0
    //         ? 'icono'
    //         : 'red';
    //     }
    //   }
    // }
  },
  computed: {
    InfoSemana () {
      return Object.keys(this.dia).length > 0
        ? {
          fecha: this.dia.fecha,
          cfp: totalClientesNoPlanificado(this.dia),
          dtv: totalDuracionVisita(this.dia),
          ...this.dia.datos_dia,
          }
        : {}

      // this.semana.length > 0
      //   ? this.semana.map((item) => {
      //     const CLIENTES_FUERA_DE_PLANIFICACIÓN = totalClientesNoPlanificado(item)
      //     return {fecha: item.fecha, cfp: CLIENTES_FUERA_DE_PLANIFICACIÓN, ...item.datos_dia }
      //   })
      //   : []
    },
    totalVisita(){
      if(Object.keys(this.dia).length === 0)
        return 0

      const VISITAS_NO_PLANIFICADAS = totalClientesNoPlanificado(this.dia)
      const VISITAS_DEL_DIA = this.dia?.datos_dia?.cv ?? 0

      return VISITAS_DEL_DIA + VISITAS_NO_PLANIFICADAS
    },
    progressColor () {
      return Object.keys(this.dia).length > 0 && this.dia.dia_semana ? this.colorsProgress[this.dia.dia_semana - 1] : 'blue-grey'
    },
  },
  filters: {
    diaMes(date) {
      return moment(date).format("DD")
    },
    diaSemana(date) {
      return moment(date).format("dddd")
    },
    mes(date) {
      return moment(date).format("MMMM");
    },
    hora(time) {
       return time != "null" ? moment(time).format('hh:mm a') : '';
    },
  }
}
</script>
<style>
.label-visitas {
  font-size: 0.895rem !important;
  letter-spacing: 0.1rem;
  line-height: 0.625rem;
  text-transform: uppercase;
  font-family: "Open Sans" !important;
}
</style>
